import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { configPropertyList } from '../../store/actions/configPropertyActions/configPropertyListActions';
import { Link } from 'react-router-dom';

function ConfigProperties() {
    const dispatch = useDispatch();
    const configPropertiesReducer = useSelector((state) => state.configPropertyListReducers);

    useEffect(() => {
        dispatch(configPropertyList());
    }, [dispatch]);

    const columns = [
        {
            name: 'Type',
            selector: (row) => row.type,
            sortable: true,
            style: { width: '120px' } // Adjust width
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            style: { width: '200px' } // Adjust width
        },
        {
            name: 'Value',
            selector: (row) => row.value,
            sortable: true,
            style: { width: '300px' } // Adjust width
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="flex justify-end space-x-2 w-full">
                    <Link
                        to={`/update-config-property`}
                        state={{ row }}
                        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded shadow-md transition-all"
                    >
                        Update
                    </Link>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            right: true, // Align column content to the right
        },

    ];

    return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen p-8 font-sans">
            {/* Header */}
            <div className="flex items-center justify-between bg-white rounded-lg p-6 shadow-md mb-6">
                <h1 className="m-0 text-[2.4rem] font-bold text-gray-800">Config Properties</h1>

            </div>

            {/* Data Table */}
            <div className="bg-white rounded-lg shadow-md p-6">
                {Array.isArray(configPropertiesReducer?.data) && configPropertiesReducer.data.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={configPropertiesReducer.data}
                        pagination
                        highlightOnHover
                        customStyles={{
                            rows: {
                                style: {
                                    fontSize: '16px',
                                    color: '#4a4a4a',
                                    fontWeight: 500,
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    backgroundColor: '#f1f5f9',
                                    color: '#2c3e50',
                                },
                            },
                            cells: {
                                style: {
                                    padding: '8px', // Adjust padding for tighter spacing
                                },
                            },
                        }}
                    />
                ) : (
                    <div className="text-gray-600 text-[2.5rem] text-center mt-8">
                        No records to display.
                    </div>
                )}
            </div>
        </div>
    );
}

export default ConfigProperties;
