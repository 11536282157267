import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { rateCardList, rateCardListReset } from '../../store/actions/rateCardActions/rateCardListActions';

function RateCard() {
    const dispatch = useDispatch();
    const rateCardReducer = useSelector((state) => state.rateCardListReducers);

    useEffect(() => {
        dispatch(rateCardList({}));
    }, [dispatch]);

    useEffect(() => {
        if (rateCardReducer?.status === 200) {
            dispatch(rateCardListReset());
        }
    }, [rateCardReducer, dispatch]);

    const columns = [
        { name: 'ID', selector: (row) => row.rateCardId, sortable: true , width: '10rem',},
        { name: 'Name', selector: (row) => row.name, sortable: true },
        { name: 'Platform', selector: (row) => row.platform, sortable: true },
        { name: 'Campaign Type', selector: (row) => row.campaignType, sortable: true },
        { name: 'Country', selector: (row) => row.country?.countryName, sortable: true },
        { name: 'Cost Per 1K', selector: (row) => row.costPer1k, sortable: true },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="flex justify-end space-x-2 w-full">
                    <Link
                        to={`/update-create-rate-card`}
                        state={{ row }}
                        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded shadow-md transition-all"
                    >
                        Update
                    </Link>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            right: true,
        },
    ];

    return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen p-8 font-sans">
            {/* Header */}
            <div className="flex items-center justify-between bg-white rounded-lg p-6 shadow-md mb-6">
                <h1 className=" m-0 text-[2.4rem] font-bold text-gray-800">Rate Card</h1>
                <Link
                    to="/update-create-rate-card"
                    className="bg-blue-500 hover:bg-blue-700 text-white text-[1.5rem] py-2 px-6 rounded shadow-md transition-all"
                >
                    + Create Rate Card
                </Link>
            </div>

            {/* Data Table */}
            <div className="bg-white rounded-lg shadow-md p-6">
                {Array.isArray(rateCardReducer?.data) && rateCardReducer.data.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={rateCardReducer.data}
                        pagination
                        highlightOnHover
                        theme="default"
                        customStyles={{
                            rows: {
                                style: {
                                    fontSize: '16px',
                                    color: '#4a4a4a',
                                    fontWeight: 500,
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    backgroundColor: '#f1f5f9',
                                    color: '#2c3e50',
                                },
                            },
                        }}
                    />
                ) : (
                    <div className="text-gray-600 text-[2.5rem] text-center mt-8">
                        No records to display.
                    </div>
                )}
            </div>
        </div>
    );
}

export default RateCard;
