import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import { userListFilter, userListFilterReset } from "../../store/actions/userActions/userListActions";
import {
    markUserStatus,
    markUserStatusReset
} from "../../store/actions/userActions/markUserStatusActions";
import {useToast} from "../../hooks/useToast";

Modal.setAppElement('#root'); // Set root element to prevent screen readers from reading the modal content.

function User() {
    const dispatch = useDispatch();
    const toast = useToast();
    const userListReducer = useSelector(state => state.userListReducers);
    const markActiveInactiveUserReducer = useSelector(state => state.markUserStatusReducers)

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userTypeFilter, setUserTypeFilter] = useState('BRAND');
    const [userStatusFilter, setUserStatusFilter] = useState(false);

    useEffect(() => {
        dispatch(userListFilter({userType:userTypeFilter , isEnabled:userStatusFilter}));
    }, [userTypeFilter,userStatusFilter]);

    useEffect(() => {
        if (userListReducer?.status === 200) {
            console.log("user data", userListReducer.data);
            dispatch(userListFilterReset());
        } else if (userListReducer?.status) {
            dispatch(userListFilterReset());
        }
    }, [userListReducer]);

    useEffect(() => {
        if (markActiveInactiveUserReducer?.status === 200) {
            console.log("user data ====", markActiveInactiveUserReducer.message);
            toast(markActiveInactiveUserReducer.message, "success");
            dispatch(userListFilter({userType:userTypeFilter , isEnabled:userStatusFilter}));
            dispatch(markUserStatusReset());
        } else if (markActiveInactiveUserReducer?.status) {
            dispatch(markUserStatusReset());
            toast(markActiveInactiveUserReducer.message, "error");
        }
    }, [markActiveInactiveUserReducer]);

    const openApproveModal = (data) => {
        setSelectedUser(data);
        setIsApproveModalOpen(true);
    };

    const openRejectModal = (data) => {
        setSelectedUser(data);
        setIsRejectModalOpen(true);
    };

    const closeApproveModal = () => {
        setIsApproveModalOpen(false);
        setSelectedUser(null);
    };

    const closeRejectModal = () => {
        setIsRejectModalOpen(false);
        setSelectedUser(null);
    };

    const handleApprove = () => {
        if (selectedUser) {
            console.log('Approved brand ID:', selectedUser);
            dispatch(markUserStatus({
                "userId": selectedUser.userId,
                "enabled": true
            }));
            closeApproveModal();
        }
    };

    const handleReject = () => {
        if (selectedUser) {
            console.log('Rejected brand ID:', selectedUser);
            dispatch(markUserStatus({
                "userId": selectedUser.userId,
                "enabled": false
            }));
            closeRejectModal();
        }
    };

    const handleTypeFilterChange = (e) => {
        setUserTypeFilter(e.target.value);
    };

    const handleStatusFilterChange = (e) => {
        setUserStatusFilter(e.target.value == "true" ? true : false);
    };


    const columns = [
        {
            name: 'Name',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
        },
        {
            name: 'User Type',
            selector: row => row.userType,
            sortable: true,
            width: '15rem',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true,  // Enables text wrapping
            minWidth: '12rem',
        },
        {
            name: 'Country',
            selector: row => row.country?.countryName?row.country?.countryName:'----',
            sortable: true,
            width: '15rem',
        },
        {
            name: 'Phone No',
            selector: row => row.phoneNumber?row.phoneNumber:'----',
            sortable: true,
            wrap: true,  // Enables text wrapping
            width: '15rem',

        },
        {
            name: 'Actions',
            width: '150px', // Set a specific width for the "Actions" column
            cell: row => (
                <div className="flex items-center justify-center space-x-2 w-full">
                    {row.isEnabled ? (

                        <button
                            onClick={() => openRejectModal(row)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            InActive
                        </button>
                    ) : (
                        <button
                            onClick={() => openApproveModal(row)}
                            className=" bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Active
                        </button>
                    )}
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen p-8 font-sans">
            {/* Header */}
            <div className="flex items-center justify-between bg-white rounded-lg p-6 shadow-md mb-6">
                <h1 className="m-0 text-[2.4rem] font-bold text-gray-800">User</h1>

            </div>
            <div className="flex space-x-5">

            <div className="mb-4 text-2xl flex items-center">
                <label className="mr-4 font-semibold text-gray-700">Filter by type:</label>
                <select
                    value={userTypeFilter}
                    onChange={handleTypeFilterChange}
                    className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"
                >
                    <option value="BRAND">BRAND</option>
                    <option value="INFLUENCER">INFLUENCER</option>
                </select>
            </div>

            <div className="mb-4 text-2xl flex items-center">
                <label className="mr-4 font-semibold text-gray-700">Filter by status:</label>
                <select
                    value={userStatusFilter}
                    onChange={handleStatusFilterChange}
                    className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2"
                >
                    <option value="true">Active</option>
                    <option value="false">In Active</option>
                </select>
            </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">


                {Array.isArray(userListReducer?.data) && userListReducer.data.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={userListReducer.data}
                        pagination
                        highlightOnHover
                        theme="default"
                        customStyles={{
                            rows: {
                                style: {
                                    fontSize: '16px',
                                    color: '#4a4a4a',
                                    fontWeight: 500,
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    backgroundColor: '#f1f5f9',
                                    color: '#2c3e50',
                                },
                            },
                        }}
                    />
                ) : (
                    <div className="text-gray-600 text-[2.5rem] text-center mt-8">
                        No records to display.
                    </div>
                )}


            </div>

            {/* Approval Confirmation Modal */}
            <Modal
                isOpen={isApproveModalOpen}
                onRequestClose={closeApproveModal}
                contentLabel="Confirm Approve"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                    },
                }}
            >
                <div className="text-[2rem] mb-5 font-bold">Confirm Active user</div>
                <p className="text-[2rem] mb-5">Are you sure you want to Active this user?</p>
                <div className="mt-3 float-right">
                    <button onClick={handleApprove}
                            className="bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                        Yes
                    </button>
                    <button onClick={closeApproveModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </Modal>

            {/* Rejection Confirmation Modal */}
            <Modal
                isOpen={isRejectModalOpen}
                onRequestClose={closeRejectModal}
                contentLabel="Confirm Reject"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        width: '500px',
                    },
                }}
            >
                <div className="text-[2rem] mb-5 font-bold">Confirm In Active User</div>
                <p className="text-[2rem] mb-5">Are you sure you want to In Active this user?</p>
                <div className="mt-3 float-right">
                    <button onClick={handleReject}
                            className="bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                        Yes
                    </button>
                    <button onClick={closeRejectModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default User;
